<template>
    <div>
        <v-container fluid>
            <v-card>
                <v-img
                    class="white--text align-top"
                    height="200px"
                    gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                    :src="`/images/settings.jpg`"
                >
                    <v-card-title justify-top>
                        Administrar
                    </v-card-title>
                </v-img>
                <v-card-text>
                    <v-row>
                        <v-col xs="6" sm="3" md="2" lg="2" xl="2" v-for="item in mods" :key="item.id">
                            <v-hover
                                v-slot:default="{ hover }"
                            >
                                <v-sheet
                                    v-ripple="{ class: `blue--text` }"
                                    class="pa-2 d-flex flex-column justify-center align-center"
                                    :elevation="hover ? 5 : 1"
                                    height="100"
                                    @click="$router.push({path: item.ruta})"
                                >
                                    <v-icon
                                        size="70"
                                    >
                                        {{item.icon}}
                                    </v-icon>
                                    {{item.etiqueta}}

                                </v-sheet>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>

    </div>
</template>

<script>
export default {
    data:()=>({
        mods:[
            {id:1, etiqueta:'Usuarios', ruta:'/admin/usuarios', icon:'mdi-account-multiple'},
            {id:2, etiqueta:'Catálogos', ruta:'/admin/catalogos', icon:'mdi-table-multiple'},
            {id:3, etiqueta:'Unir CIP', ruta:'/admin/joincip', icon:'mdi-account-switch'},
            {id:4, etiqueta:'Archivos', ruta:'/archivos', icon:'mdi-file'},
            {id:5, etiqueta:'Borrados', ruta:'/admin/borrados', icon:'mdi-delete'},
            {id:6, etiqueta:'Mensajes', ruta:'/admin/bb', icon:'mdi-chat'},
        ]
    }),

}
</script>

<style>

</style>